import React, { useState, useRef } from "react"

import { useImageDimensions, useViewportIntersection } from "../../../../hooks"
import { classNames, canUseWebP } from "../../../../utilities"
import { Image as ImageType } from "../../../../types"

import * as styles from "./Image.module.scss"

interface Props {
  item: Partial<ImageType>
  fullscreen?: boolean
}

export function Image({ item, fullscreen }: Props) {
  const element = useRef<HTMLDivElement>(null)
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(true)
  const imageDimensions = useImageDimensions(item.originalDimensions!)

  useViewportIntersection(element.current, () => {
    setShow(true)
  })

  const src = canUseWebP() && item.srcWebp ? item.srcWebp : item.src
  const srcSet = canUseWebP() && item.srcSetWebp ? item.srcSetWebp : item.srcSet

  const imageMarkup = show ? (
    <img
      className={styles.Image}
      srcSet={srcSet}
      src={src}
      alt={item.description}
      onLoad={handleLoad}
    />
  ) : null

  const loaderMarkup = loading ? (
    <div className={styles.Loader} style={imageDimensions} />
  ) : null

  const imageContainerClassName = classNames(
    styles.ImageContainer,
    loading && styles.isLoading,
    fullscreen && styles.isFullscreen
  )

  return (
    <div
      className={imageContainerClassName}
      // style={fullscreen ? undefined : imageDimensions}
      ref={element}
    >
      {imageMarkup}
      {loaderMarkup}
    </div>
  )

  function handleLoad() {
    setLoading(false)
  }
}
