export interface Image {
  type: "Image"
  description?: string
  srcSet: string
  srcSetWebp?: string | null
  src: string
  srcWebp?: string | null
  originalDimensions: {
    height: number
    width: number
  }
}

export function isImage(input: any): input is Image {
  return input != null && typeof input === "object" && input.type === "Image"
}
