import React, { useState, useRef, useEffect } from "react"

import {
  useEventListener,
  usePrevious,
  useBrowserSniffer,
  useResponsiveLayout,
} from "../../../../hooks"
import { ScrollContext } from "../ScrollContext"

import * as styles from "./HorizontalScroll.module.scss"

interface Props {
  children: React.ReactNode
}

export function HorizontalScroll({ children }: Props) {
  const [offset, setOffset] = useState(0)
  const previousOffset = usePrevious(offset)
  const element = useRef<HTMLDivElement>(null)

  useEventListener("wheel", handleWheel, element.current, {
    passive: false,
  })

  const { isFirefox } = useBrowserSniffer()
  const { isPortrait } = useResponsiveLayout()
  const isLongScroll = offset - previousOffset > 1000

  useEffect(() => {
    const scrollElement = getScrollElement()

    if (scrollElement == null) {
      return
    }

    if (isLongScroll) {
      scrollElement.style.scrollBehavior = "smooth"
    }

    scrollElement[isPortrait ? "scrollTop" : "scrollLeft"] = offset

    if (isLongScroll) {
      setTimeout(() => {
        scrollElement.style.scrollBehavior = ""
      }, 1000)
    }
  }, [offset, element])

  return (
    <ScrollContext.Provider
      value={{ offset, scrollableElement: element.current, setOffset }}
    >
      <div className={styles.HorizontalScroll} ref={element}>
        {children}
      </div>
    </ScrollContext.Provider>
  )

  function getScrollElement() {
    if (isPortrait) {
      return document.scrollingElement as HTMLElement
    }

    if (isFirefox) {
      return document.scrollingElement as HTMLElement
    } else {
      return element.current
    }
  }

  function handleWheel(event: WheelEvent) {
    event.preventDefault()

    const scrollElement = getScrollElement()

    if (scrollElement == null) {
      return
    }

    setOffset(
      scrollElement[isPortrait ? "scrollTop" : "scrollLeft"] +
        event.deltaY +
        event.deltaX
    )
  }
}
