import React, { useRef, useState } from "react"

import { Photo } from "../../../../types"
import { classNames } from "../../../../utilities"
import { useEventListener } from "../../../../hooks"
import { BackLink } from "../BackLink"

import * as styles from "./Info.module.scss"

interface Props {
  item: Photo
}

type Direction = "left" | "right"

export function Info({ item }: Props) {
  const element = useRef<HTMLDivElement>(null)
  const [scroll, setScroll] = useState(false)
  const [direction, setDirection] = useState<Direction>("right")

  useEventListener("wheel", handleWheel, element.current, {
    capture: true,
    passive: false,
  })

  useEventListener("scroll", handleScroll, element.current, {
    capture: true,
    passive: false,
  })

  const buyButtonClassName = classNames(
    styles.BuyButton,
    !item.productVariant?.availableForSale && styles.isDisabled
  )

  const buyButtonMarkup = item.productVariant ? (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`https://shop.maxhoffmann.co/cart/${item.productVariant?.id}:1?channel=buy_button`}
      className={buyButtonClassName}
      onClick={handleClick}
      aria-disabled={!item.productVariant?.availableForSale}
    >
      {getBuyButtonText()}
    </a>
  ) : null

  const excerptMarkup =
    item.excerpt == null
      ? null
      : item.excerpt.split(/\n/).map((paragraph, index) => (
          <p key={`excerpt-${index}`} className={styles.Excerpt}>
            {paragraph}
          </p>
        ))

  return (
    <div className={styles.Info}>
      <div className={styles.Section}>
        <BackLink />
        <h2 className={styles.Title}>{item.title}</h2>
        <div className={styles.Content} ref={element}>
          {excerptMarkup}
        </div>
      </div>
      {item.productVariant?.amount ? (
        <div className={styles.Section}>
          <h3 className={styles.PriceInfo}>
            * Shipping rates are calculated at checkout
          </h3>
          {renderAmount()}
        </div>
      ) : null}
      <div className={styles.Section}>{buyButtonMarkup}</div>
    </div>
  )

  function handleClick(event: React.MouseEvent) {
    if (!item.productVariant?.availableForSale) {
      event.preventDefault()
    }
  }

  function handleWheel(event: WheelEvent) {
    setDirection(event.deltaX > 0 ? "right" : "left")

    const target = event.target as Element

    if (
      target &&
      !scroll &&
      target.closest &&
      target.closest(`.${styles.Content}`)
    ) {
      event.stopPropagation()
      event.stopImmediatePropagation()
    }
  }

  function handleScroll(event: Event) {
    if (element.current == null) {
      return
    }

    const scrollOffset =
      element.current.scrollHeight - element.current.clientHeight

    const isAtTop = element.current.scrollTop === 0
    const isAtBottom = scrollOffset === element.current.scrollTop + 1

    if (scrollOffset === 0) {
      setScroll(true)
    } else if (direction === "right" && isAtBottom) {
      setScroll(true)
    } else if (direction === "left" && isAtTop) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }

  function getBuyButtonText() {
    return item.productVariant?.availableForSale ? "Buy now" : "Sold out"
  }

  function renderAmount() {
    return (
      <h2 className={styles.Price}>
        <span className={styles.isSmall}>$</span>
        {getAmountBefore()}
        <span className={styles.isSmall}>{getAmountAfter()}</span>
      </h2>
    )
  }

  function getAmountBefore() {
    return item.productVariant?.amount.split(".")[0]
  }

  function getAmountAfter() {
    const amount = item.productVariant?.amount.split(".")[1]

    if (amount === "0") {
      return "00"
    }

    return amount
  }
}
