import { useRef, useEffect } from "react"

export function useEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  handler: (event: WindowEventMap[K]) => void,
  element?: HTMLElement | Window | null,
  options?: boolean | AddEventListenerOptions
) {
  const savedHandler = useRef<(event: WindowEventMap[K]) => void>()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const currentElement =
      typeof window !== "undefined" && element != null ? window : element

    if (currentElement == null) return

    const eventListener = (event: WindowEventMap[K]) => {
      if (savedHandler == null || savedHandler.current == null) {
        return
      }

      savedHandler.current(event)
    }

    currentElement.addEventListener(eventName, eventListener as any, options)

    return () => {
      const capture = isAddEventListenerOptions(options)
        ? Boolean(options.capture)
        : Boolean(options)

      currentElement.removeEventListener(eventName, eventListener as any, {
        capture,
      })
    }
  }, [eventName, element])
}

function isAddEventListenerOptions(
  options?: boolean | AddEventListenerOptions
): options is AddEventListenerOptions {
  return typeof options === "object"
}
