import React, { useState, useCallback } from "react"

import { Link } from "../Link"
import { TextStyle } from "../TextStyle"
import {
  useResponsiveLayout,
  ScrollWidthValue,
  SCROLL_WIDTH_INITIAL_VALUE,
} from "../../hooks"
import { classNames } from "../../utilities"
import {
  Photo,
  isPhoto,
  Image as ImageType,
  isImage,
  TextContent,
} from "../../types"

import {
  HorizontalScroll,
  Slide,
  SlideAction,
  Frame,
  Image,
  Container,
  Header,
  Navigation,
  Info,
  PreviewPhoto,
  ScrollIndicator,
  TextContainer,
} from "./components"
import * as styles from "./Slideshow.module.scss"

interface Props {
  items: (Photo | ImageType | TextContent)[]
  title?: string
  showHeader?: boolean
  action?: SlideAction
  children?: React.ReactNode
}

export const Slideshow = ({
  items,
  title,
  action,
  showHeader = false,
  children,
}: Props) => {
  const { isPortrait } = useResponsiveLayout()
  const [containerWidth, setContainerWidth] = useState<ScrollWidthValue>(
    SCROLL_WIDTH_INITIAL_VALUE
  )

  const isSingleItem = items.length === 1
  const slideshowClassName = classNames(styles.Slideshow)

  const handleWidth = useCallback(
    (width: ScrollWidthValue) => {
      setContainerWidth(width)
    },
    [setContainerWidth]
  )

  const slidesMarkup = items.map((item, index) => {
    if (isPhoto(item)) {
      return <Slide key={item.id} item={item} number={index} action={action} />
    }

    if (isImage(item)) {
      return <Image key={item.src} item={item} />
    }

    return <TextContainer key={item.content[0].value} item={item} />
  })

  const image = items[0]

  const infoMarkup =
    isSingleItem && isPhoto(image) ? <Info item={image} /> : null

  const previewPhotos =
    isSingleItem && isPhoto(image) && image.previewPhotos
      ? image.previewPhotos.map((photo, index) => (
          <PreviewPhoto key={index} {...photo} />
        ))
      : null

  return (
    <HorizontalScroll>
      <div className={slideshowClassName}>
        <Navigation top>
          <TextStyle>
            <Link to="/">Photos</Link>
          </TextStyle>
          <TextStyle>
            <Link to="/about">About</Link>
          </TextStyle>
          <TextStyle>
            <Link to="https://instagram.com/realmaxhoffmann" external>
              Instagram
            </Link>
          </TextStyle>
        </Navigation>
        {showHeader ? <Header title={title} /> : null}
        <Frame width={containerWidth} top isPortrait={isPortrait} />
        <Container width={containerWidth} onWidth={handleWidth}>
          <ScrollIndicator />
          {children}
          {slidesMarkup}
          {infoMarkup}
          {previewPhotos}
        </Container>
        <Frame width={containerWidth} bottom isPortrait={isPortrait} />
        <Navigation bottom>
          <TextStyle variant="camera">
            <Link
              to="mailto:max@maxhoffmann.co"
              title="Send me an email"
              external
            >
              Max Hoffmann
            </Link>{" "}
            &copy; 2020
          </TextStyle>
        </Navigation>
      </div>
    </HorizontalScroll>
  )
}
