// extracted by mini-css-extract-plugin
export var Slide = "Slide-module--Slide--18lUj";
export var Fullscreen = "Slide-module--Fullscreen--1D2B9";
export var Link = "Slide-module--Link--DyILf";
export var isLoading = "Slide-module--isLoading--chd6a";
export var Button = "Slide-module--Button--28BLv";
export var isFullscreen = "Slide-module--isFullscreen--2EDRA";
export var FilmNumber = "Slide-module--FilmNumber--1iGds";
export var Icons = "Slide-module--Icons--RS5RT";
export var View = "Slide-module--View--3H1v-";
export var ShoppingBag = "Slide-module--ShoppingBag--10yWI";