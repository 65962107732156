export {
  convertAboutQuery,
  convertSlideshowQuery,
  convertImageQuery,
  convertNotFoundQuery,
} from "./converters"

export function isServer(): boolean {
  return typeof window === "undefined"
}

export function isClient(): boolean {
  return !isServer()
}

export function canUseWebP() {
  var elem = document.createElement("canvas")

  if (Boolean(elem.getContext && elem.getContext("2d"))) {
    return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0
  }

  return false
}

export function classNames(base: string, ...variants: any[]) {
  const variantClassNames = variants
    .filter(Boolean)
    .map(String)
    .map(className => className.trim())
    .join(" ")
    .trim()

  return `${base || ""} ${variantClassNames}`.trim()
}
