import React from "react"

import { TextContent } from "../../../../types"
import { BackLink } from "../BackLink"

import * as styles from "./TextContainer.module.scss"

interface Props {
  item: TextContent
}

export function TextContainer({ item }: Props) {
  const content = item.content.map(content => {
    if (content.type === "BackLink") {
      return <BackLink key={content.value} />
    }

    if (content.type === "Title") {
      return (
        <h1 key={content.value} className={styles.Title}>
          {content.value}
        </h1>
      )
    }

    if (content.type === "Paragraph") {
      return (
        <p key={content.value} className={styles.Paragraph}>
          {content.value}
        </p>
      )
    }
  })

  return <div className={styles.TextContainer}>{content}</div>
}
