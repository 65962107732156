import React from "react"

import { Image } from "../Image"

interface Props {
  src: string
  srcSet: string
  srcWebp?: string | null
  srcSetWebp?: string | null
  originalDimensions: {
    height: number
    width: number
  }
}

export function PreviewPhoto(props: Props) {
  return <Image item={{ ...props, description: "Preview Photo" }} />
}
