import { useEffect } from "react"

export function useViewportIntersection(
  element: HTMLElement | null,
  callback: Function
) {
  useEffect(() => {
    if (element == null) {
      return
    }

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.target === element) {
          callback()
          unobserve(element)
        }
      })
    })

    observer.observe(element)

    return () => {
      unobserve(element)
    }

    function unobserve(element: HTMLElement) {
      observer.unobserve(element)
      observer.disconnect()
    }
  }, [element, callback])
}
