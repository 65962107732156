import React from "react"

import { TextStyle } from "../TextStyle"
import { Link } from "../Link"

import * as styles from "./HeroTitle.module.scss"

interface Props {
  title: string
  subtitle: string
}

export const HeroTitle = ({ title, subtitle }: Props) => (
  <div className={styles.ComingSoon}>
    <div className={styles.Wrapper}>
      <h1 className={styles.Title}>{title}</h1>
      <h3 className={styles.Subtitle}>{subtitle}</h3>
      <TextStyle variant="camera">
        <Link to="/" title="Go back">
          Back
        </Link>
      </TextStyle>
    </div>
  </div>
)
