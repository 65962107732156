import { useEventListener } from "../useEventListener"
import { isServer } from "../../utilities"

export function useRealViewportUnits() {
  if (isServer()) {
    return
  }

  useEventListener("resize", handleResize, window)

  function handleResize() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }
}
