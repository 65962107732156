import React from "react"

import { classNames } from "../../utilities"

import * as styles from "./TextStyle.module.scss"

interface Props {
  children: React.ReactNode
  variant?: "camera" | "negative"
}

export function TextStyle({ children, variant = "negative" }: Props) {
  const textStyleClassName = classNames(
    styles.TextStyle,
    variant === "camera" && styles.isCameraPrint,
    variant === "negative" && styles.isNegativePrint
  )

  return <span className={textStyleClassName}>{children}</span>
}
