import React from "react"
import Helmet, { HelmetProps } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import { Image } from "../../types"

interface Props {
  description?: string
  lang?: string
  meta?: HelmetProps["meta"]
  images?: Image[]
  title: string
  path?: string
}

export function SEO({
  description = "",
  lang = "en",
  meta = [],
  images = [],
  title,
  path = "/",
}: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            domain
          }
        }
      }
    `
  )

  if (images.length !== 0) {
    const imageMetas = images.map(image => ({
      property: "og:image",
      content: image.src,
    }))
    meta.push(...imageMetas)
  }

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={site.siteMetadata.title}
      titleTemplate={`%s | ${title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:url`,
          content: `https://${site.siteMetadata.domain}${path}`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        ...meta,
      ]}
    />
  )
}
