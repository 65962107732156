import { useState } from "react"

import { useEventListener } from "../useEventListener"

export function useResponsiveLayout() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEventListener("resize", handleResize, window)

  return {
    isPortrait: windowDimensions.height > windowDimensions.width,
    isLandscape: windowDimensions.height < windowDimensions.width,
    isSquare: windowDimensions.height === windowDimensions.width,
    windowDimensions,
  }

  function handleResize() {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }
}
