import React, { useEffect } from "react"

import { useResponsiveLayout } from "../useResponsiveLayout"

export type WidthValue = "initial" | number
export const INITIAL_VALUE = "initial"

export function useScrollWidth(
  element: React.RefObject<HTMLElement>,
  onChange: (value: WidthValue) => void
) {
  const { windowDimensions } = useResponsiveLayout()

  useEffect(() => {
    onChange(INITIAL_VALUE)

    setTimeout(() => {
      if (element == null || element.current == null) return

      onChange(element.current.scrollWidth)
    }, 0)
  }, [element, windowDimensions])
}
