import { useResponsiveLayout } from "../useResponsiveLayout"

const RATIO = 0.7

interface Dimensions {
  height: number
  width: number
}

export function useImageDimensions(dimensions: Dimensions) {
  const { isPortrait, windowDimensions } = useResponsiveLayout()

  const windowDimension = isPortrait
    ? windowDimensions.width
    : windowDimensions.height

  const imageDimension = windowDimension * RATIO

  if (isPortrait) {
    const widthRatio = imageDimension / dimensions.width
    const height = dimensions.height * widthRatio

    return { width: imageDimension, height }
  } else {
    const heightRatio = imageDimension / dimensions.height
    const width = dimensions.width * heightRatio

    return { width, height: imageDimension }
  }
}
