import React from "react"

import { classNames } from "../../../../utilities"

import * as styles from "./Navigation.module.scss"

interface Props {
  children: React.ReactNode
  top?: boolean
  bottom?: boolean
}

export function Navigation({ children, top, bottom }: Props) {
  const navigationClassName = classNames(
    styles.Navigation,
    top && styles.isTop,
    bottom && styles.isBottom
  )

  return <div className={navigationClassName}>{children}</div>
}
