import React from "react"
import Link from "gatsby-link"

import * as styles from "./BackLink.module.scss"

interface Props {}

export function BackLink({}: Props) {
  return (
    <div>
      <Link to="/" title="Return to homepage" className={styles.BackLink}>
        <SvgImage />
        Back to homepage
      </Link>
    </div>
  )
}

function SvgImage() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 256 256"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <polygon points="79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128 		" />
        </g>
      </g>
    </svg>
  )
}
