// extracted by mini-css-extract-plugin
export var Info = "Info-module--Info--34qWG";
export var Section = "Info-module--Section--1Pr2c";
export var Content = "Info-module--Content--N_sCM";
export var Title = "Info-module--Title--3XtxC";
export var Excerpt = "Info-module--Excerpt--2rOlR";
export var Price = "Info-module--Price--3wqHk";
export var PriceInfo = "Info-module--PriceInfo--2jQh6";
export var isSmall = "Info-module--isSmall--1kXM9";
export var BuyButton = "Info-module--BuyButton--2gW88";
export var pulsating = "Info-module--pulsating--15S8T";
export var isDisabled = "Info-module--isDisabled--raHXD";
export var none = "Info-module--none--3b21X";