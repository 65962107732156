import React from "react"
import { graphql, StaticQuery } from "gatsby"

import { classNames, canUseWebP } from "../../../../utilities"
import { ScrollWidthValue } from "../../../../hooks"

import * as styles from "./Frame.module.scss"

interface Props {
  width: ScrollWidthValue
  top?: boolean
  bottom?: boolean
  isPortrait: boolean
}

export function Frame({
  width,
  top: isTop,
  bottom: isBottom,
  isPortrait,
}: Props) {
  const frameClassName = classNames(
    styles.Frame,
    isTop && styles.isTop,
    isBottom && styles.isBottom
  )

  return (
    <StaticQuery
      query={graphql`
        query Frame {
          top: file(relativePath: { eq: "top.jpg" }) {
            childImageSharp {
              fluid(quality: 50, maxWidth: 2000) {
                src
                srcWebp
              }
            }
          }
          bottom: file(relativePath: { eq: "bottom.jpg" }) {
            childImageSharp {
              fluid(quality: 50, maxWidth: 2000) {
                src
                srcWebp
              }
            }
          }
          topVertical: file(relativePath: { eq: "bottom-vertical.jpg" }) {
            childImageSharp {
              fluid(quality: 50, maxWidth: 2000) {
                src
                srcWebp
              }
            }
          }
          bottomVertical: file(relativePath: { eq: "top-vertical.jpg" }) {
            childImageSharp {
              fluid(quality: 50, maxWidth: 2000) {
                src
                srcWebp
              }
            }
          }
        }
      `}
      render={data => {
        const { top, topVertical, bottom, bottomVertical } = data

        let imageData = null

        if (isTop) {
          imageData = isPortrait
            ? topVertical.childImageSharp.fluid
            : top.childImageSharp.fluid
        } else {
          imageData = isPortrait
            ? bottomVertical.childImageSharp.fluid
            : bottom.childImageSharp.fluid
        }

        return (
          <div
            className={frameClassName}
            style={{
              width: isPortrait ? undefined : width,
              backgroundImage: `url(${
                canUseWebP() ? imageData.srcWebp : imageData.src
              })`,
            }}
          />
        )
      }}
    />
  )
}
