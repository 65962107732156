import React from "react"

import * as styles from "./Header.module.scss"

interface Props {
  title?: string
}

export function Header({ title }: Props) {
  return (
    <header className={styles.Header}>
      <h1 className={styles.Title}>Max Hoffmann</h1>
      {title ? <h3 className={styles.Subtitle}>{title}</h3> : null}
    </header>
  )
}
