import React, { useRef } from "react"
import { graphql, StaticQuery } from "gatsby"

import { useScrollWidth, ScrollWidthValue } from "../../../../hooks"
import { canUseWebP } from "../../../../utilities"

import * as styles from "./Container.module.scss"

interface Props {
  children: React.ReactNode
  width: ScrollWidthValue
  onWidth: (scrollWidth: ScrollWidthValue) => void
}

export function Container({ children, width, onWidth }: Props) {
  const element = useRef<HTMLDivElement>(null)

  useScrollWidth(element, onWidth)

  return (
    <>
      <StaticQuery
        query={graphql`
          query Container {
            pattern: imageSharp(
              fluid: { originalName: { eq: "grain-pattern.jpg" } }
            ) {
              fluid(quality: 100, maxWidth: 2000) {
                src
                srcWebp
              }
            }
          }
        `}
        render={data => {
          const imageData = data.pattern.fluid

          return (
            <div
              className={styles.Container}
              ref={element}
              style={{
                width: width,
                backgroundImage: `url(${
                  canUseWebP() ? imageData.srcWebp : imageData.src
                })`,
              }}
            >
              {children}
            </div>
          )
        }}
      />
    </>
  )
}
