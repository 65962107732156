import { useContext } from "react"

import { useResponsiveLayout } from "../../../../hooks"
import { ScrollContext } from "../../components/ScrollContext"

export function useScroller() {
  const context = useContext(ScrollContext)
  const { isPortrait } = useResponsiveLayout()

  if (context == null) {
    throw new Error("ScrollContext missing")
  }

  const { scrollableElement, offset = 0, setOffset } = context

  const windowDimension = isPortrait ? window.innerHeight : window.innerWidth
  const dimension =
    (isPortrait
      ? document.scrollingElement?.scrollHeight
      : scrollableElement?.scrollWidth) || 0

  return {
    offset,
    hasScrolledToTheEnd:
      (offset < 0 ? 0 : offset) + windowDimension > dimension - 150,
    scrollToEnd() {
      setOffset && setOffset(10000)
    },
    updateOffset(offset: number) {
      setOffset && setOffset(offset)
    },
  }
}
