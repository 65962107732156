import React from "react"
import GatsbyLink, { GatsbyLinkProps } from "gatsby-link"

interface Props extends GatsbyLinkProps<{}> {
  external?: boolean
}

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>

const CustomGatsbyLink: React.FC<Omit<GatsbyLinkProps<{}>, "ref">> = props => (
  <GatsbyLink {...props} />
)

export function Link({ children, external, ...props }: Props) {
  const linkMarkup = external ? (
    <a href={props.to} target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </a>
  ) : (
    <CustomGatsbyLink {...props}>{children}</CustomGatsbyLink>
  )
  return linkMarkup
}
