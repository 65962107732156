import React, { useRef } from "react"

import { useImageDimensions } from "../../../../hooks"
import { classNames } from "../../../../utilities"
import { Photo } from "../../../../types"

import { Image } from "../Image"
import { Fullscreen, useFullScreenHandle } from "../Fullscreen"

import * as styles from "./Slide.module.scss"

export type Action = "fullscreen" | "url"

interface Props {
  item: Photo
  number?: number
  action?: Action
}

export function Slide({ item, number, action = "url" }: Props) {
  const fullscreenElement = useRef<HTMLDivElement>(null)
  const imageDimensions = useImageDimensions(item.feature.originalDimensions)
  const handle = useFullScreenHandle()

  const slideClassName = classNames(
    styles.Slide,
    handle.active && styles.isFullscreen
  )

  return (
    <div className={slideClassName}>
      {number != null ? <h3 className={styles.FilmNumber}>{number}</h3> : null}
      {getContentMarkup()}
    </div>
  )

  function getContentMarkup() {
    if (action === "url") {
      return (
        <a
          href={`/images/${item.handle}`}
          title={`Go to ${item.title}`}
          className={styles.Link}
        >
          <Image item={item.feature} />

          <div className={styles.Icons}>
            {item.productVariant ? (
              <div className={styles.ShoppingBag}>
                <ShoppingBagSVG />
              </div>
            ) : null}

            <div className={styles.View}>
              <ViewSVG />
            </div>
          </div>
        </a>
      )
    }

    if (action === "fullscreen") {
      return (
        <div
          className={styles.Fullscreen}
          ref={fullscreenElement}
          style={imageDimensions}
        >
          <Fullscreen handle={handle}>
            <button
              type="button"
              role="button"
              title={
                handle.active
                  ? "Click to close fullscreen"
                  : "Click to fullscreen"
              }
              className={styles.Button}
              onClick={handle.enter}
              style={handle.active ? undefined : imageDimensions}
            >
              <Image item={item.feature} fullscreen={handle.active} />
            </button>
          </Fullscreen>
        </div>
      )
    }

    return null
  }

  function ShoppingBagSVG() {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 4H19C19.5523 4 20 4.44771 20 5V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V5C4 4.44772 4.44771 4 5 4ZM2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5ZM12 12C9.23858 12 7 9.31371 7 6H9C9 8.56606 10.6691 10 12 10C13.3309 10 15 8.56606 15 6H17C17 9.31371 14.7614 12 12 12Z"
          fill="currentColor"
        />
      </svg>
    )
  }

  function ViewSVG() {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3C17.5915 3 22.2898 6.82432 23.6219 12C22.2898 17.1757 17.5915 21 12 21C6.40848 21 1.71018 17.1757 0.378052 12C1.71018 6.82432 6.40848 3 12 3ZM12 19C7.52443 19 3.73132 16.0581 2.45723 12C3.73132 7.94186 7.52443 5 12 5C16.4756 5 20.2687 7.94186 21.5428 12C20.2687 16.0581 16.4756 19 12 19Z"
          fill="currentColor"
        />
      </svg>
    )
  }
}
