import { Image } from "./image"
import { ProductVariant } from "./product-variant"

export interface Photo {
  id: string
  type: "Photo"
  title: string
  excerpt: string
  handle: string
  feature: Image
  productVariant: ProductVariant | null
  previewPhotos: Image[]
}

export function isPhoto(input: any): input is Photo {
  return input != null && typeof input === "object" && input.type === "Photo"
}
